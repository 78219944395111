import useAxios from "axios-hooks";
import { Col, Container, Image, Row, Button } from "react-bootstrap";
import { useParams } from "react-router";
import { FaStar } from "react-icons/fa";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";

const ProductDetails = () => {
  let { productId } = useParams();
  let navigate = useNavigate();
  const [{ data, loading, error }] = useAxios(
    "https://fakestoreapi.com/products/" + productId
  );
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error!</p>;
  if (data)
    return (
      <>
        <NavBar />
        <Container>
          <Row>
            <Container>
              <Image width={360} src={data.image} alt="" />
            </Container>
            <Col>
              <h1>{data.title}</h1>
              <Row>
                <h1>${data.price}</h1>
                <h3>
                  <FaStar />
                  {data.rating.rate}({data.rating.count})
                </h3>
              </Row>
              <p>{data.description}</p>
              <Button variant="primary" onClick={() => navigate(-1)}>
                Go Back
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
};

export default ProductDetails;
