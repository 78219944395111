import { configureStore } from "@reduxjs/toolkit";
import { filterReducer } from "./actions/filterProducts";
import { combineReducers } from "redux";
import {
  searchProducts,
  selectCategory,
  getProductsList,
} from "./actions/filterProducts";

export const store = configureStore({
  reducer: combineReducers({
    filter: filterReducer,
  }),
});

store.dispatch(searchProducts());
store.dispatch(selectCategory());
store.dispatch(getProductsList());
