import ProductCard from "./ProductCard";
import { Container, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import useAxios from "axios-hooks";
import { getProductsList } from "../actions/filterProducts";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import HomeBanner from "./HomeBanner";

const Shop = () => {
  const dispatch = useDispatch();
  let baseUrl = "https://fakestoreapi.com/products/";
  const filteredProducts = useSelector(
    (state) => state.filter.filteredProducts
  );
  console.log(filteredProducts);
  const [{ data, loading, error }] = useAxios(baseUrl);

  if (loading)
    return (
      <>
        <Container
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
        >
          <Player
            autoplay
            loop
            src="https://assets3.lottiefiles.com/packages/lf20_aiyjqg6f.json"
            style={{ height: "300px", width: "300px" }}
          >
            <Controls
              visible={false}
              buttons={["play", "repeat", "frame", "debug"]}
            />
          </Player>
          <h1 className="d-flex justify-content-center">Loading ...</h1>
        </Container>
      </>
    );
  if (error) return <h1>Error!</h1>;
  if (data) filteredProducts === undefined && dispatch(getProductsList(data));
  if (!filteredProducts?.length)
    return (
      <>
        <Container
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
        >
          <Player
            autoplay
            loop
            src="https://assets6.lottiefiles.com/packages/lf20_rDputF5tPy.json"
            style={{ height: "300px", width: "300px" }}
          >
            <Controls
              visible={false}
              buttons={["play", "repeat", "frame", "debug"]}
            />
          </Player>
          <h3 className="d-flex justify-content-center">
            Couldn't find the product
          </h3>
        </Container>
      </>
    );

  return (
    <>
      <HomeBanner />

      <Container>
        <Row
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
          className="d-flex justify-content-evenly"
        >
          {filteredProducts?.length &&
            filteredProducts.map((product, i) => (
              <ProductCard
                key={product.id}
                id={product.id}
                title={product.title}
                price={product.price}
                category={product.category}
                description={product.description}
                image={product.image}
              ></ProductCard>
            ))}
        </Row>
      </Container>
    </>
  );
};

export default Shop;
