import { Player, Controls } from "@lottiefiles/react-lottie-player";

import React from "react";
import { Container } from "react-bootstrap";
import NavBar from "./NavBar";

class Error extends React.Component {
  render() {
    return (
      <>
        <NavBar />
        <Container
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
        >
          <Player
            autoplay
            loop
            src="https://assets6.lottiefiles.com/packages/lf20_rDputF5tPy.json"
            style={{ height: "300px", width: "300px" }}
          >
            <Controls
              visible={false}
              buttons={["play", "repeat", "frame", "debug"]}
            />
          </Player>
          <h1 className="d-flex justify-content-center">404</h1>
          <h3 className="d-flex justify-content-center">
            Couldn't find the page
          </h3>
        </Container>
      </>
    );
  }
}

export default Error;
