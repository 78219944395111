import { Card, Button } from "react-bootstrap";

const ProductCard = ({ id, title, price, category, image }) => {
  return (
    <Card
      style={{
        width: "18rem",
        marginBottom: "30",
      }}
    >
      <Card.Img width="100" height="300" variant="top" src={image} />
      <Card.Body>
        <Card.Title>
          {title} ${price}
        </Card.Title>
        <Card.Text>{category}</Card.Text>
        <Button variant="primary" href={"/shop/" + id}>
          View Details
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
