import { useRef } from "react";
import { Navbar, Nav, NavDropdown, Container, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import useAxios from "axios-hooks";
import { searchProducts, selectCategory } from "../actions/filterProducts";

const NavBar = () => {
  const [{ data }] = useAxios("https://fakestoreapi.com/products/categories");
  const valueRef = useRef("");
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state) => state.filter.category);

  if (data)
    return (
      <>
        <Navbar
          style={{
            paddingLeft: "50px",
            paddingRight: "50px",
          }}
          expand="lg"
          className="navbar-dark sticky-top bg-dark justify-content-md-between p"
        >
          <Navbar.Brand href="/">
            <img
              alt=""
              src="https://cdn.iconscout.com/icon/free/png-256/free-logo-1889531-1597591.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            React Store
          </Navbar.Brand>
          <Container fluid="lg" className="justify-content-lg-center">
            <Form>
              <Form.Group controlId="searchInputControl">
                <Form.Control
                  ref={valueRef}
                  onChange={() =>
                    dispatch(searchProducts(valueRef.current.value))
                  }
                  type="text"
                  placeholder="Search"
                />
              </Form.Group>
            </Form>
          </Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="Categories" id="basic-nav-dropdown">
                {data.map((category, i) => (
                  <NavDropdown.Item
                    active={category === selectedCategory}
                    key={category}
                    onClick={() => dispatch(selectCategory(category))}
                  >
                    {category}
                  </NavDropdown.Item>
                ))}
                <NavDropdown.Item
                  active={"all" === selectedCategory}
                  onClick={() => dispatch(selectCategory("all"))}
                >
                  all
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
};

export default NavBar;
