import { Carousel, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const HomeBanner = () => {
  return (
    <Container style={{ textAlign: "center" }}>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://img.freepik.com/free-photo/leather-shoe-collection-men-fashion-choices-generated-by-ai_188544-11870.jpg?t=st=1687505851~exp=1687506451~hmac=a33d6441b06c1f56ad0d530ff24e56824162b487315c2adb19f9ec83b2472d7d&w=2000"
            alt="First slide"
          />
          <Carousel.Caption>
            <h2>Fassion Accessories - Clearance Sale</h2>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://img.freepik.com/free-photo/fashionable-clothing-collection-hanging-modern-boutique-generated-by-ai_188544-20872.jpg?t=st=1687506077~exp=1687506677~hmac=3446931fb6c3fc1020f76c3c16989ce9af95a6bee28f8b25eb2700ca2fd6b7a8&w=2000"
            alt="Second slide"
          />

          <Carousel.Caption>
            <h2>Latest Collection - Mens Wear</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://img.freepik.com/free-photo/ornate-gold-necklace-symbolizes-hindu-spirituality-wealth-generated-by-ai_188544-38873.jpg?t=st=1687506243~exp=1687506843~hmac=aac35f13118781789f0a2fe1c6ce8bc4ab9dbeb1cc39b1068f4b578f4704ecf1&w=2000"
            alt="Third slide"
          />

          <Carousel.Caption>
            <h2>Jewellery</h2>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </Container>
  );
};

export default HomeBanner;
