import { createSlice } from "@reduxjs/toolkit";

const filterProductsSlice = createSlice({
  name: "filter",
  initialState: {
    products: [],
    filteredProducts: [],
    searchTerm: "",
    category: "all",
  },
  reducers: {
    searchProducts: (state, action) => {
      console.log("searchProducts", action);

      return {
        products: state.products,
        filteredProducts: state.products.filter(
          (product) =>
            product.title?.toLowerCase().includes(action.payload) &&
            (state.category === "all" || state.category === undefined
              ? true
              : product.category === state.category)
        ),
        searchTerm: action.payload,
        category: state.category,
      };
    },
    selectCategory: (state, action) => {
      console.log("selectCategory", action);

      return {
        products: state.products,
        filteredProducts: state.products.filter(
          (product) =>
            (action.payload === "all"
              ? true
              : product.category === action.payload) &&
            product.title?.toLowerCase().includes(state.searchTerm ?? "")
        ),
        searchTerm: state.searchTerm,
        category: action.payload,
      };
    },
    getProductsList: (state, action) => {
      console.log("get", action);
      return {
        products: action.payload,
        filteredProducts: action.payload,
        searchTerm: state.searchTerm,
        category: state.category,
      };
    },
  },
});

export const { searchProducts, selectCategory, getProductsList } =
  filterProductsSlice.actions;
export const filterReducer = filterProductsSlice.reducer;
